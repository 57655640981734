import styled from "styled-components";
import Rectangle from "../../../assets/img/img1.png";

export const EmbedCradleWrapper = styled("div")`
  background: url(${Rectangle}) center no-repeat;
  /* height: 475vh; */
  height: max-content;
  background-size: cover;
  /* padding: 4rem 0px; */
  width: 100%;
`;
