import styled from "styled-components";
import BGIMG from "../../../assets/img/greenfield.png";

export const Wrapper = styled("div")`
  height: 140vh;
  width: 100vw;
  position: relative;
  background: url(${BGIMG}) center no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  display: grid;

  section {
    flex: 1;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-image: linear-gradient(
      transparent,
      #000000a6,
      #000000a6,
      #000000a6
    );

    div {
      /* border: solid; */
      height: 50%;
      width: 60%;
      color: #fff;
      font-weight: bolder;
      text-align: center;
      span {
        font-size: 50px;
      }
    }
  }
`;

export const FlexScroll = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100vw;
  margin-top: 2.5rem;
  position: relative;
`;

export const ScrollImage = styled("img")`
  height: 100%;
  width: 60%;
`;

export const CharacterImage = styled("img")`
  position: absolute;
  bottom: -15rem;
  // height: 160%;
  left: -5rem;
  top: -10rem;
  height: 1150px;
`;

export const ScrollText = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  position: absolute;
  height: 70%;

  h1,
  h3,
  span {
    padding: 0;
    margin: 0;
    color: #693403;
    font-family: "Kabel Bd";
    font-size: 6.5rem;
  }

  h3 {
    font-size: 3rem;
  }
  span {
    font-size: medium;
  }
`;

export const StartExplorationImage = styled("img")`
  cursor: pointer;
  margin-top: 1rem;
  height: 50px;
  width: 150px;
  &:hover {
    width: 155px;
  }
`;

export const SeparatorLine = styled("div")`
  position: relative;
  width: 100%;
`;

export const SeparatorLineImage = styled("img")`
  position: absolute;
  top: -26px;
  left: -8px;
  height: 100px;
  z-index: 999;
  width: 110%;
`;
export const Shadow = styled("div")`
  width: 100%;
  height: 80%;
  background-image: linear-gradient(transparent, black);
`;
