import { LearnWEWrapper } from "./learnwithembed.styles";
import EmbedCradle from "../../../../assets/img/embednote.png";
import Children from "../../../../assets/images/gamified-learning-hero.png";

const Index: React.FC = () => {
  return (
    <LearnWEWrapper>
      <div className="section-2-a flex">
        <div className="section-2-a-left">
          {/* <img alt="" src={EmbedCradle} /> */}
          <p>KEEP YOUR 0 - 5 YEAR OLD</p>
          <p>
            Learning & Engaged with <b>EmBED</b>{" "}
          </p>
          <span>Plant The Seed With Lifelong Fearless Creativity</span>
        </div>
        <div className="section-2-a-right">
          <img alt="" src={Children} />
        </div>
      </div>
    </LearnWEWrapper>
  );
};

export default Index;
