import {
  Wrapper,
  FlexScroll,
  ScrollImage,
  CharacterImage,
  ScrollText,
  StartExplorationImage,
  SeparatorLine,
  SeparatorLineImage,
  Shadow,
} from "./herosection.style";
import Scroll from "../../../assets/img/scrolll.png";
import Character from "../../../assets/img/char.png";
import Learning from "../../../assets/img/learning.png";
import Revolutionalize from "../../../assets/img/revolutionalize.png";
import Adventure from "../../../assets/img/adventure.png";
import StartBtn from "../../../assets/img/startexpol.png";
import SeparatorBorderLine from "../../../assets/img/roughborder.png";
import Header from "../../../components/NewHeader";
import React from "react";

const Index: React.FC = () => {
  return (
    <React.Fragment>
      <Header />
      <Wrapper>
        <FlexScroll>
          <CharacterImage alt="" src={Character} />
          <ScrollImage alt="" src={Scroll} />
          <ScrollText>
            {/* <img alt="" src={Learning} /> */}
            {/* <img alt="" src={Revolutionalize} /> */}
            {/* <img alt="" src={Adventure} /> */}
            <h1>LEARNING</h1>
            <h3>Revolutionalized</h3>
            <span>
              An education adventure that keeps your child engaged and delighted
            </span>
            <StartExplorationImage alt="" src={StartBtn} />
          </ScrollText>
        </FlexScroll>
        <section>
          {
            /* <Shadow></Shadow> */
            <div>
              <span>The African Child</span>
              <br />
              <span>Learning Companion</span>
            </div>
          }
        </section>

        {/* <SeparatorLine>
          <SeparatorLineImage alt="" src={SeparatorBorderLine} />
        </SeparatorLine> */}
      </Wrapper>
    </React.Fragment>
  );
};

export default Index;

//const Index: React.FC = () => {
//   return (
//     <div>
//       <Wrapper>
//         <Header />
//         <div className="scroll-char flex">
//           <img alt="" className="character" src={Character} />
//           <img alt="" className="scroll-img" src={Scroll} />
//           <div className="scroll-text flex">
//             <img alt="" className="" src={Learning} />
//             <img alt="" className="" src={Revolutionalize} />
//             <img alt="" className="" src={Adventure} />
//             <img alt="" className="start-explo" src={StartBtn} />
//           </div>
//         </div>
//         <div className="separator-line">
//           <img alt="" src={Separatoraline} />
//         </div>
//       </Wrapper>
//     </div>
//   );
// };

export const Line = () => (
  <SeparatorLine>
    <SeparatorLineImage alt="" src={SeparatorBorderLine} />
  </SeparatorLine>
);
