import styled from "styled-components";
import ListBorder from "../../assets/img/listborder.png";

export const LandingHeader = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100vw;
  height: 70px;

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-holder {
    height: 12vh;
    flex-basis: 30%;

    img {
      width: 90px;
      height: 60px;
    }
  }

  .list {
    height: 10vh;
    flex-basis: 60%;
    justify-content: flex-end;

    .li {
      color: #fff;
      cursor: pointer;
      margin: 0 1rem;
      height: 30px;
      width: 100px;
      border-radius: 12px;
      border: 5px solid gold;
      position: relative;
      justify-content: space-between;
      background: url(${ListBorder}) center no-repeat;

      img {
        left: -12px;
        width: 30px;
        height: 30px;
        position: absolute;
      }

      span {
        width: 100%;
        height: 100%;
        display: flex;
        font-weight: bold;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .join-us {
    height: 10vh;
    flex-basis: 20%;

    img {
      width: 45%;
      height: 45px;
      cursor: pointer;
    }
  }
`;

export const Wrapper = styled("div")`
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 999;
  display: flex;
  position: fixed;
  align-items: center;
  background-color: #fff;
  justify-content: space-evenly;
`;
