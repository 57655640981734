import React from "react";
import { LandingHeader, Wrapper } from "./header.styles";
import Logos from "../../assets/images/EMBED1.png";
import JoinUs from "../../assets/img/joinusbtn.png";
import Dot from "../../assets/img/listdot.png";

const Index = () => {
  const NavList = [
    {
      icon: Dot,
      name: "Home",
    },
    {
      icon: Dot,
      name: "Cradle",
    },
    {
      icon: Dot,
      name: "Genius",
    },
    {
      icon: Dot,
      name: "About",
    },
    {
      icon: Dot,
      name: "Contact",
    },
  ];

  return (
    <LandingHeader>
      <Wrapper>
        <div className="logo-holder flex">
          <img src={Logos} alt="logos" />
        </div>
        {/* ------------------Mapped Header Menu -------------------------------------- */}
        {/* <div className="list flex">
        {NavList.map(({ icon, name }) => {
          // console.log(icon, name);
          return (
            <div className="li flex">
              {" "}
              <img alt="" src={icon} /> {name}
            </div>
          );
        })}
        <div className="join-us flex">
          <img className="flex" src={JoinUs} alt="join-us-btn" />
        </div>
      </div> */}
        {/* ------------------Header Menu without map--------------------------------------- */}
        <div className="list flex">
          <div className="li flex">
            {" "}
            <img alt="" src={Dot} /> <span>Cradle</span>
          </div>
          <div className="li flex">
            {" "}
            <img alt="" src={Dot} /> <span>Genius</span>
          </div>
          <div className="li flex">
            {" "}
            <img alt="" src={Dot} /> <span>ICE</span>
          </div>
          <div className="li flex">
            {" "}
            <img alt="" src={Dot} /> <span>Elevate</span>
          </div>
          <div className="li flex">
            {" "}
            <img alt="" src={Dot} /> <span>About</span>
          </div>
        </div>
        <div className="join-us flex">
          <img className="flex" src={JoinUs} alt="join-us-btn" />
        </div>
      </Wrapper>
    </LandingHeader>
  );
};

export default Index;
