import styled from "styled-components";

export const LearnWEWrapper = styled("div")`
  display: flex;
  align-items: center;
  height: 95vh;
  width: 100%;
  justify-content: space-between;

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-2-a-left {
    color: #fff;
    flex-basis: 50%;
    height: 100%;
    /* border: solid red; */
    text-align: center;

    p {
      font-size: 50px;
      b {
        font-size: 55px;
        font-weight: bolder;
        padding: 0 3rem;
      }
    }
    span {
      font-size: 25px;
    }
  }
  .section-2-a-right {
    flex-basis: 50%;
    width: 50vw;
    height: 100%;
    /* overflow: hidden; */
    position: relative;
    /* border: solid red; */

    img {
      /* object-fit: cover; */
      /* position: absolute; */
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      height: 450px;
      border-radius: 50px;
      width: 100%;
      box-shadow: 0px 3px 50px #f6f6f629;
      transform: matrix(0.85, 0.33, -0.36, 0.93, 0, 0);
    }
  }
`;
